<template>
    <div class="loginimg">
    <el-form ref="form" label-width="70px" :inline="true" class="login-container" :model="form" :rules="rules">
        <div class="login_title">系统登录</div>
        <el-form-item label="用户名" prop="username">
            <el-input v-model="form.username" placeholder="请输入账号"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
            <el-input type="password" v-model="form.password" placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item>
            <el-button @click="submit" style="margin-left: 105px;margin-top: 10px;" type="primary">登录</el-button>
        </el-form-item>
    </el-form>
</div>
  </template>
  
  <script>
  // import Mock from 'mockjs'
  import Cookie from 'js-cookie'
  import { getMenu } from '../api' 
  export default {
    data() {
        return {
            form: {
                username: '',
                password: ''
            },
            rules: {
                username: [
                    { required: true, trigger: 'blur', message: '请输入用户名' }
                ],
                password: [
                    { required: true, trigger: 'blur', message: '请输入密码' }
                ]
            }
        }
    },
    methods: {
        // 登录
        submit() {
            // // token信息
            // const token = Mock.Random.guid()
            
  
            // 校验通过
            this.$refs.form.validate((valid) => {
                if (valid) {
                    getMenu(this.form).then(({ data }) => {
                        console.log(data)
                        if (data.status === "success") {
                        //     // token信息存入cookie用于不同页面间的通信
                            Cookie.set('token', data.data.token)
                            // 获取菜单的数据，存入store中
                            this.$store.commit('setMenu', data.data.menu)
                            this.$store.commit('addMenu', this.$router)
                            // 跳转到首页
                            this.$router.push('/home')
                        } else {
                            this.$message.error(data.message);
                        }
                    })
                }
            })
        }
    }
  }
  </script>                           
  <style lang="less" scoped>
  .loginimg{
    width: 100%;
    height: 100vh;
    width: 100%;
    background: url(../../src/assets/images/03.jpg) no-repeat;
    background-size: 100% 100%;
    background-repeat: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .login-container {
    width: 350px;
    // border: 1px solid #eaeaea;
    // margin: 0px auto;
    padding: 35px 35px 15px 35px;
    background-color: #fff;
    border-radius: 15px;
    // box-shadow: 0 0 25px #cac6c6;
    box-sizing: border-box;
    // opacity: 0.9;
    .login_title {
        text-align: center;
        margin-bottom: 40px;
        color: #746565;
    }
    .el-input {
        width: 198px;
    }
    .el-button{
        background-color: #DF6124;
        border: none;
    }
  }
  </style>