import http from "../utils/request";

//请求首页数据
export const getData = () => {
    //返回一个promise对象
    return http.get('notify')
}

//登录接口
export const getMenu = (data) => {
    //返回一个promise对象
    return http.post('webUserLogin', data)
}